import { useState ,useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal'
import noimg from '../background/noimg.jpg'
import { Link } from 'react-router-dom';
import {useSelector,useDispatch  } from "react-redux";
import { getalldata } from '../redux/actions/postslice' ;


function Cards() {


  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const handleModalClose = () => setShowModal(false);
  const handleModalShow = (image) => {
    setSelectedImage(image);
    setShowModal(true);
  };

  const dispatch=useDispatch();
  const dataposts= useSelector(state=>state.posts); 


  useEffect(()=>  {
  
      dispatch(getalldata());
    


}, [dataposts.posts])

  return (
    
    dataposts && dataposts.length > 0 ? [...dataposts].sort((a, b) => a.Camelnumber - b.Camelnumber).map((d)=>{return <Card key={d.id} style={{ width: '16rem' }}>
   <Card.Img  variant="top" src={d.images[0]==null ? noimg : d.images[0].image} style={{marginTop:15, width: '14rem',height: '12rem' }} onClick={() =>
    handleModalShow(d.images[0] == null ? noimg : d.images[0].image)
  }/>
   <Card.Body >
     <Card.Title ><span style={{color: 'red',fontFamily: 'Noto Naskh Arabic, serif',fontSize:20,wordBreak:'break-word',}}>{"الاسم :"}</span> <span style={{fontSize:15,wordBreak:'break-word',}}>{d.Camelname}</span></Card.Title>
   </Card.Body>
   <ListGroup className="d-flex list-group-flush">
     <ListGroup.Item ><span style={{color: 'red',fontFamily: 'Noto Naskh Arabic, serif',fontSize:20}}>{"الرقم :"}</span> <span style={{fontSize:15,wordBreak:'break-word',}}>{d.Camelnumber}</span></ListGroup.Item>
     <ListGroup.Item >
     <span style={{color: 'red',fontFamily: 'Noto Naskh Arabic, serif',fontSize:20}}>{"النوع :"}</span><span style={{fontSize:15,wordBreak:'break-word',}}>{d.Cameltype}</span> 
     </ListGroup.Item>
   </ListGroup>
   <div>
   <Card.Body className='d-flex justify-content-center align-items-end'>
     <Link to={'Carddetails'} state={d.id} >
     <Button variant="primary" >عرض التفاصيل</Button>
     </Link>
   </Card.Body>
   </div>
   <Modal show={showModal} onHide={handleModalClose} size="lg"  >
              <Modal.Header closeButton></Modal.Header>
              <Modal.Body >
                <img src={d.images == null ? noimg : selectedImage} alt={d.Camelname} style={{ width: '100%', height: '100%' }} />
              </Modal.Body>
            </Modal>
 </Card>
 
 }) : <div style={{ 
  display: 'flex', 
  justifyContent: 'center', 
  alignItems: 'flex-start',
  marginTop:'20px',
}}>
  <p style={{ 
    fontSize: '3rem', 
    textAlign: 'center',
    color:'red',
    fontFamily: 'Noto Naskh Arabic , serif'
  }}>لا يوجد بيانات</p>
</div>
  );
}

export default Cards

