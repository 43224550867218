import React ,{useState , useEffect, useRef } from 'react'
import { useLocation } from "react-router-dom";
import { getbyid } from '../redux/actions/postslice';
import {useSelector,useDispatch  } from "react-redux";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
import './Carddetails.css'
import {Carousel ,Modal,Button  }from 'react-bootstrap';
import { useReactToPrint } from "react-to-print";


function Carddetails() {
    const {state}  = useLocation();
    const [selectedImage, setSelectedImage] = useState(null);

 

    const  id = state  ;
    

    const dispatch=useDispatch();
    const dataposts= useSelector(state=>state.posts); 
    
    
    
    const handleImageClick = (image) => {
      setSelectedImage(image);
      
    };
  
    const handleCloseModal = () => {
      setSelectedImage(null);
    };

    useEffect(()=>  {
  
      dispatch(getbyid(id));
      
  }, [dispatch])
  
    const printref = useRef();
    const handleprint = useReactToPrint({
      content:()=> printref.current,
     pageStyle:'print'
    })
 

  return (
    
    dataposts.posts != null ?  <div className="app" ref={printref}>
    {
      dataposts.posts.map(item =>(
        <div className="details" key={""}>
          
           <div className="box">
            <div className="row">       
              <span>الرقم : {dataposts.posts[0].Camelnumber}</span>
              <span><Button size="md" style={{float: "left",margin:5 ,backgroundColor:"orange"}} onClick={handleprint}>طباعة</Button></span>
            </div>
            
            <h2 className='h2'> <span className='title'>{"الاسم:"}</span> {dataposts.posts[0].Camelname}</h2> 
            <hr className='line' />
            <p> <span class='title'>{"اسم الاب :"}</span> {dataposts.posts[0].Camelfather}</p> 
            <hr className='line' />
            <p><span className='title'>{"اسم الام :"}</span>  {dataposts.posts[0].Camelmother}</p>
            <hr className='line'/>
            <p><span className='title'>{"النوع:"}</span>{dataposts.posts[0].Cameltype}</p>
            <hr className='line' />
            <p><span className='title'>{"الموقع:"}</span>{dataposts.posts[0].Camelflag}</p>
            <hr className='line' />
            <p><span className='title'>{"الشريحة:"}</span>{dataposts.posts[0].Camelslide}</p>
            <hr className='line' />
            <p><span className='title'>{"الحالة:"}</span>{dataposts.posts[0].Camelstatus}</p>
            <hr className='line' />
            <p><span className='title'>{"المالك:"}</span>{dataposts.posts[0].Camelowner}</p>
            <hr className='line' />
            <p><span className='title'>{"المصدر:"}</span>{dataposts.posts[0].Camelsource}</p>
            <hr className='line' />
            <p><span className='title'>{"الانتاج:"}</span>{dataposts.posts[0].Camelproduction}</p>
            <hr className='line' />
            <p><span className='title'>{"الانجازات:"}</span>{dataposts.posts[0].Camelachievements}</p>
            <hr className='line' />
            <p><span className='title'>{"اضراب:"}</span>{dataposts.posts[0].Camelmarried}</p>
            <hr className='line' />
            <p><span className='title'>{"تاريخ اللقحة:"}</span>{dataposts.posts[0].Cameldatevaccine}</p>
            <hr className='line' />
            <p><span className='title'>{"القسم:"}</span>{dataposts.posts[0].Camelpartions}</p>
            <hr className='line' />
            <p><span className='title'>{"السن:"}</span>{dataposts.posts[0].Camelage}</p>
            <hr className='line' />
            <p><span className='title'>{"الملاحظات:"}</span>{dataposts.posts[0].Camelnotes}</p>
            <hr className='line' />
           </div>

            <div className='big-img'>
            
           <Carousel style={{height: '400px', width: '400px' }} >
            
            {dataposts.images && dataposts.images.map(image => {
              return  <Carousel.Item  key={image.id} style={{ height: '350px', width: '350px' ,marginLeft:"25px"}} >   <img
              key={image.id}
              src={image.image}
              alt="camel"
              style={{maxWidth: '100%', maxHeight: '100%', width: '100%', height: '100%', objectFit: 'contain'}}
              onClick={() => handleImageClick(image.image)}
            /></Carousel.Item>
            })}
              {dataposts.videos && dataposts.videos.map((video) => {
               return ( <Carousel.Item key={video.id} style={{ height: '420px', width: '340px',marginLeft:"35px" }}>
                  <video src={video.video} controls style={{ objectFit: 'cover', height: '100%', width: '100%',maxWidth: '100%', maxHeight: '83%', }} />
               </Carousel.Item>
              );
               })}
            </Carousel><style>{`
               .carousel-control-prev-icon,
               .carousel-control-next-icon {
                margin-top: -20px;
                
               }
               .carousel-control-prev-icon {
                 margin-right: 40px;
                 
               }
               .carousel-control-next-icon {
                 margin-left: 40px;
               }
             `}</style>
            <Modal show={selectedImage} onHide={handleCloseModal} centered>
            <Modal.Header closeButton />
            <Modal.Body>
            <img src={selectedImage} alt="camel" style={{ objectFit: 'contain', width: '100%', height: '100%' }} />
            </Modal.Body>
            </Modal>
           </div>
          
           </div>
      ))
    }

  </div>
 :
    <Container className='mt-3' fluid>
    <Box  sx={{ display: 'flex' }}>
        <h1> الرجاء الانتظار جاري تحميل البيانات</h1>
        <CircularProgress  style={{color:"black", width: "3rem", height: "3rem", marginRight:8 }}/>
      </Box>
    </Container>
  )
}

export default Carddetails