import React, { useState,useRef } from "react";
import { Form, Button, Modal,Spinner  } from "react-bootstrap";
import { adddata,getalldata } from "../redux/actions/postslice";
import {useDispatch  } from "react-redux";
import { AiOutlineClose } from 'react-icons/ai';
import { FaPlus } from "react-icons/fa";


function Add({ handleCloseAddsModal,notify }) {
  const dispatch=useDispatch();
  const [media, setmedia] = useState([])
  const [isLoading, setIsLoading] = useState(false);

  const [Data, setData] = useState({
    Camelnumber: "",
    Camelname: "",
    Camelfather: "",
    Camelmother: "",
    Camelslide: "",
    Camelnotes: "",
    Cameltype: "",   
    Camelflag: "",
    Camelstatus: "",
    Camelowner: "",
    Camelsource: "",
    Camelproduction: "",
     Camelachievements: "",
     Camelmarried: "",
    Cameldatevaccine: "",
    Camelpartions: "",
    Camelage: "",
  });
  const [error, setError] = useState("");

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setData({ ...Data, [name]: value });
    
  };

  const errorRef = useRef("");

  const handleSubmit = async (event) => {
    event.preventDefault();
     if (!Data.Camelnumber) {
      errorRef.current = "الرجاء ادخال رقم";
      window.alert(errorRef.current);
      return;
    }
    
    const formData = new FormData();
    await  Object.values(media).forEach(files=>{
      formData.append('media', files)
    });
    formData.append('Camelnumber', Data.Camelnumber);
    formData.append('Camelname', Data.Camelname);
  formData.append('Camelfather', Data.Camelfather);
  formData.append('Camelmother', Data.Camelmother);
  formData.append('Camelslide', Data.Camelslide);
  formData.append('Camelnotes', Data.Camelnotes);
    formData.append('Cameltype', Data.Cameltype);
  formData.append('Camelflag', Data.Camelflag); 
  formData.append('Camelstatus', Data.Camelstatus);
  formData.append('Camelowner', Data.Camelowner);
  formData.append('Camelsource', Data.Camelsource);
  formData.append('Camelproduction', Data.Camelproduction);
  formData.append('Camelachievements', Data.Camelachievements);
  formData.append('Camelmarried', Data.Camelmarried);
  formData.append('Cameldatevaccine', Data.Cameldatevaccine);
  formData.append('Camelpartions', Data.Camelpartions);
  formData.append('Camelage', Data.Camelage);

  setIsLoading(true);
 
    try {

      if (window.confirm("هل تريد الاضافة ؟")) {
        await dispatch(adddata(formData));
        setIsLoading(false); 
      notify("تم الاضافة بنجاح","Success")
      setTimeout(() => {
        dispatch(getalldata());
      },);
      }
      setIsLoading(false);
      
    } catch (error) {
      console.log(error);
      notify("يوجد خطأ","Error")
    }
    handleCloseAddsModal();

    
  };





  return (
    <>
      <Form>
      <Form.Group className="mb-2" controlId="formCamelName">
          <Form.Label> الرقم :</Form.Label>
          <Form.Control
            type="number"
            placeholder="ادخل الرقم"
            name="Camelnumber"
            value={Data.Camelnumber}
            onChange={handleInputChange}
          />
        </Form.Group>
        <Form.Group className="mb-2" controlId="formCamelNumber">
          <Form.Label>الاسم :</Form.Label>
          <Form.Control
            type="text"
            placeholder="ادخل الاسم"
            name="Camelname"
            value={Data.Camelname}
            onChange={handleInputChange}
          />
        </Form.Group>     
        <Form.Group className="mb-2" controlId="formCamelFather">
          <Form.Label>اسم الاب :</Form.Label>
          <Form.Control
            type="text"
            placeholder="ادخل اسم الاب"
            name="Camelfather"
            value={Data.Camelfather}
            onChange={handleInputChange}
          />
        </Form.Group>
        <Form.Group className="mb-2" controlId="formCamelMother">
          <Form.Label>اسم الام :</Form.Label>
          <Form.Control
            type="text"
            placeholder="ادخل اسم الام"
            name="Camelmother"
            value={Data.Camelmother}
            onChange={handleInputChange}
          />
        </Form.Group>
        <Form.Group className="mb-2" controlId="formCamelslide">
          <Form.Label>الشريحة :</Form.Label>
          <Form.Control
            type="text"
            placeholder="ادخل رقم الشريحة"
            name="Camelslide"
            value={Data.Camelslide}
            onChange={handleInputChange}
          />
        </Form.Group>
        <Form.Group className="mb-2" controlId="formCamelage">
          <Form.Label>السن :</Form.Label>
          <Form.Control
            type="text"
            placeholder="ادخل السن"
            name="Camelage"
            value={Data.Camelage}
            onChange={handleInputChange}
          />
        </Form.Group>
        <Form.Group className="mb-2" controlId="formCamelpartions">
          <Form.Label>القسم :</Form.Label>
          <Form.Select
           name="Camelpartions"
           value={Data.Camelpartions}
            onChange={handleInputChange}
             >
          <option value="">-- اختر القسم --</option>
          <option value="الريس">قسم الريس</option>
          <option value="الخلفات">قسم الخلفات</option>
          <option value="اللقاح">قسم اللقاح</option>
          <option value="الحيل">قسم الحيل</option>
  </Form.Select>
        </Form.Group>
        <Form.Group className="mb-2" controlId="formCamelType">
          <Form.Label>النوع :</Form.Label>
          <Form.Control
            type="text"
            placeholder="ادخل النوع"
            name="Cameltype"
            value={Data.Cameltype}
            onChange={handleInputChange}
          />
        </Form.Group>
        <Form.Group className="mb-2" controlId="formCamelFlag">
          <Form.Label>الموقع :</Form.Label>
          <Form.Control
            type="text"
            placeholder="ادخل الموقع"
            name="Camelflag"
            value={Data.Camelflag}
            onChange={handleInputChange}
          />
        </Form.Group>
        
        <Form.Group className="mb-2" controlId="formCamelstatus">
          <Form.Label>الحالة :</Form.Label>
          <Form.Control
            type="text"
            placeholder="ادخل الحالة"
            name="Camelstatus"
            value={Data.Camelstatus}
            onChange={handleInputChange}
          />
        </Form.Group>
        <Form.Group className="mb-2" controlId="formCamelowner">
          <Form.Label>المالك :</Form.Label>
          <Form.Control
            type="text"
            placeholder="ادخل المالك"
            name="Camelowner"
            value={Data.Camelowner}
            onChange={handleInputChange}
          />
        </Form.Group>
        <Form.Group className="mb-2" controlId="formCamelsource">
          <Form.Label>المصدر :</Form.Label>
          <Form.Control
            type="text"
            placeholder="ادخل المصدر"
            name="Camelsource"
            value={Data.Camelsource}
            onChange={handleInputChange}
          />
        </Form.Group>
        <Form.Group className="mb-2" controlId="formCamelproduction">
          <Form.Label>الانتاج :</Form.Label>
          <Form.Control
            type="text"
            placeholder="ادخل الانتاج"
            name="Camelproduction"
            value={Data.Camelproduction}
            onChange={handleInputChange}
          />
        </Form.Group>
        <Form.Group className="mb-2" controlId="formCamelachievements">
          <Form.Label>الانجازات :</Form.Label>
          <Form.Control
            type="text"
            placeholder="ادخل الانجازات"
            name="Camelachievements"
            value={Data.Camelachievements}
            onChange={handleInputChange}
          />
        </Form.Group>
        <Form.Group className="mb-2" controlId="formCamelmarried">
          <Form.Label>اضراب :</Form.Label>
          <Form.Control
            type="text"
            placeholder="ادخل الاضراب"
            name="Camelmarried"
            value={Data.Camelmarried}
            onChange={handleInputChange}
          />
        </Form.Group>
        <Form.Group className="mb-2" controlId="formCameldatevaccine">
          <Form.Label>تاريخ اللقحة :</Form.Label>
          <Form.Control
            type="text"
            placeholder="ادخل تاريخ اللقحة"
            name="Cameldatevaccine"
            value={Data.Cameldatevaccine}
            onChange={handleInputChange}
          />
        </Form.Group>
       
        
        
        <Form.Group className="mb-2" controlId="formCamelnotes">
          <Form.Label>الملاحظات :</Form.Label>
          <Form.Control
           as="textarea" 
            rows={3}
            placeholder="الملاحظات"
            name="Camelnotes"
            value={Data.Camelnotes}
            onChange={handleInputChange}
          />
        </Form.Group>


        <Form.Group controlId="files" className="mb-3">
          <Form.Label>ادخل الصور والفيديو</Form.Label>
          <Form.Control onChange={(e) => {
          
          setmedia(e.target.files);
        }} type="file" multiple accept="image/*,video/*" />
        </Form.Group>
      </Form>
      <Modal.Footer>
        <Button style={{ float: "left",marginLeft:'20px' }} size="lg" variant="secondary" onClick={handleCloseAddsModal}>
        <div><AiOutlineClose size={25} style={{transform: 'scaleX(-1)', marginRight: 8,color:"black" }} /><span style={{ marginLeft: '5px', marginRight: '8px'}}>اغلاق</span></div>
        </Button>
        <Button style={{ float: "left" }} size="lg" variant="primary" onClick={handleSubmit}>
        {isLoading ? <Spinner animation="border" size="sm" /> : <div><FaPlus size={25} style={{ marginRight: 8,color:"black" }} /><span style={{ marginLeft: '5px', marginRight: '8px'}}>اضافة</span></div>}
        </Button>
      </Modal.Footer>
    </>
  );
  
}

export default Add;

