import React,{useState,useEffect} from 'react'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import {useNavigate , Outlet } from 'react-router-dom';


function Login() {

  function getSavedCredentials() {
    const savedUsername = localStorage.getItem('username');
    const savedPassword = localStorage.getItem('password');
    const savedTime = localStorage.getItem('savedTime');

    // Check if the saved time is more than 1 day ago
  if (savedTime && Date.now() - savedTime > 24 * 60 * 60 * 1000) {
    localStorage.removeItem('username');
    localStorage.removeItem('password');
    localStorage.removeItem('savedTime');
    return {username: '', password: ''};
  }
    
    return savedUsername && savedPassword
      ? { username: savedUsername, password: savedPassword }
      : { username: '', password: '' };
  }
  

  const database = 
    {
      username: "Qatar",
      password: "2234448S"
    }

    const { username, password } = getSavedCredentials();

    useEffect(() => {
      setName(username);
      setPass(password);
    }, [username, password]);
    
    const [Name, setName] = useState('')
    const [Pass, setPass] = useState('')
    const [isSubmitted, setIsSubmitted] = useState(false);
    const navigate=useNavigate()
 
  const handleSubmit = async (e) =>{
    e.preventDefault();

    if ( database.username === Name && database.password === Pass){
      setIsSubmitted(true);
      localStorage.setItem('username', Name);
      localStorage.setItem('password', Pass);
      localStorage.setItem('savedTime', Date.now());
      navigate('/Admin')
    } else {
      alert('Invalid username or password');
    }

  }
  return (
    isSubmitted ? (<Outlet/>): <Form className='col-md-4 justify-content-center align-middle'
         style={{
        backgroundColor: 'rgba(255, 255, 255, 0.2)',
        maxWidth:' 550px',
        width: "90%",
        paddingTop:"40px",
        paddingBottom:"40px",
        display:"flex" ,
        alignItems: "center",
        flexDirection: "column",
        borderRadius: "30px",
        boxshadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
        position: 'absolute',  
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)'
  
     }}
     >
        <Form.Group style={{width: "70%",}} className="mb-3" controlId="Name">
          <Form.Label>اسم المستخدم</Form.Label>
          <Form.Control type="text" value={Name}  placeholder="اسم المستخدم" onChange={(e)=>{setName(e.target.value)}}/>
          
        </Form.Group>
  
        <Form.Group style={{width: "70%",}} className="mb-3" controlId="Pass">
          <Form.Label>كلمة المرور</Form.Label>
          <Form.Control type="password" value={Pass} placeholder="كملة المرور" onChange={(e)=>{setPass(e.target.value)}}/>
        </Form.Group>
        
        <Button style={{width: "70%",}} variant="primary" type="submit" onClick={handleSubmit}>
          دخول
        </Button>
        
      </Form>
  
  
  )
}

export default Login