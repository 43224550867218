import React, { useState, useEffect  } from "react";
import {
  Form,
  Button,
  Row,
  Col,
  Modal,
  Card,
  Container,
  Spinner
} from "react-bootstrap";
import {
  getbyid,
  deleteimg,
  editdata,
  deletevid,
} from "../redux/actions/postslice";
import { useDispatch, useSelector } from "react-redux";
import { useLocation,useNavigate  } from "react-router-dom";
import { RiSave2Fill } from 'react-icons/ri';
import { BiArrowBack } from 'react-icons/bi';
import {AiTwotoneDelete} from "react-icons/ai";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import "./Carddetails.css";
function Edit() {
  
  const { state } = useLocation();

  const id = state;
  const navigate  = useNavigate();

  const handleBackclick = () => {
    navigate('/Admin');
  };

  const dispatch = useDispatch();

  const dataposts = useSelector((state) => state.posts);

  const [selectedImage, setSelectedImage] = useState(null);
  const handleImageClick = (image) => {
    setSelectedImage(image);
    
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
  };

  const [isLoading, setIsLoading] = useState(false);
  const [media, setmedia] = useState([]);
  const [Data, setData] = useState({
    Camelnumber: "",
    Camelname: "",
    Camelfather: "",
    Camelmother: "",
    Camelnotes: "",
    Cameltype: "",
    Camelflag: "",
    Camelslide: "",
    Camelstatus: "",
    Camelowner: "",
    Camelsource: "",
    Camelproduction: "",
    Camelachievements: "",
    Camelmarried: "",
    Cameldatevaccine: "",
    Camelpartions: "",
    Camelage: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setData({ ...Data, [name]: value });

  };

  const handleSubmit = async (event) => {
    
    const formData = new FormData();
    await Object.values(media).forEach((files) => {
      formData.append("media", files);
    });
    formData.append("Camelnumber", Data.Camelnumber);
    formData.append("Camelname", Data.Camelname);
    formData.append("Camelfather", Data.Camelfather);
    formData.append("Camelmother", Data.Camelmother);
    formData.append("Camelnotes", Data.Camelnotes);
    formData.append("Cameltype", Data.Cameltype);
    formData.append("Camelflag", Data.Camelflag);
    formData.append('Camelslide', Data.Camelslide);
  formData.append('Camelstatus', Data.Camelstatus);
  formData.append('Camelowner', Data.Camelowner);
  formData.append('Camelsource', Data.Camelsource);
  formData.append('Camelproduction', Data.Camelproduction);
  formData.append('Camelachievements', Data.Camelachievements);
  formData.append('Camelmarried', Data.Camelmarried);
  formData.append('Cameldatevaccine', Data.Cameldatevaccine);
  formData.append('Camelpartions', Data.Camelpartions);
  formData.append('Camelage', Data.Camelage);
    setIsLoading(true);
    try {
      if (window.confirm("هل تريد حفظ التغييرات التي اجريتها ؟")) {
        await dispatch(editdata({ id, inputData: formData }));  
      setIsLoading(false);
      notify("تم التعديل بنجاح","Success")
      await dispatch(getbyid(id));
      setmedia([]);
      }
      setIsLoading(false);
     
    } catch (error) {
      console.log(error);
      notify("يوجد خطأ","Error")
    }
    
  };

  const onDeleteImg = async (imgid) => {
    try {
      if (window.confirm("هل تريد حذف هذه الصورة ؟")) {
        await dispatch(deleteimg(imgid));
        notify("تم حذف الصورة بنجاح","Warn")
        await dispatch(getbyid(id));
      }
    } catch (error) {
      console.log(error.message);
      notify("يوجد خطأ","Error")
    }
  };

  const onDeletevid= async (vidid) => {
    try {
      if (window.confirm("هل تريد حذف هذا الفيديو ؟")) {
        await dispatch(deletevid(vidid));
        notify("تم حذف الفيديو بنجاح","Warn")
        await dispatch(getbyid(id));
        }
    
    } catch (error) {
      console.log(error.message);
      notify("يوجد خطأ","Error")
    }
  };

  const notify = (message,type) => {
    if(type==="Error")
    toast.error(message,{autoClose:2000,rtl:true,style: { width: '80%', display: 'flex', marginTop:"60px" }})
    else if (type === "Success")
    toast.success(message,{autoClose:2000,rtl:true,style: { width: '80%', display: 'flex', marginTop:"60px" }})
    else if (type === "Warn")
    toast.warn(message,{autoClose:2000,rtl:true,style: { width: '80%', display: 'flex', marginTop:"60px" }})
  }
  

  useEffect(() => {
    dispatch(getbyid(id));
  }, [dispatch]);

  useEffect(() => {
    if (dataposts.posts) {
      setData(dataposts.posts[0]);
    }
  }, [dataposts.posts]);

  return (
    <>
      {dataposts.posts != null ? (
        <Container className="mt-2 mb-2 justify-content-center align-items-center">
          <Card className="p-3">
            <Form>
            <Form.Group className="mb-2" controlId="formCamelNumber">
          <Form.Label>الاسم :</Form.Label>
          <Form.Control
            type="text"
            placeholder="ادخل الاسم"
            name="Camelname"
            value={Data.Camelname}
            onChange={handleInputChange}
          />
        </Form.Group>
        <Form.Group className="mb-2" controlId="formCamelName">
          <Form.Label> الرقم :</Form.Label>
          <Form.Control
            type="number"
            placeholder="ادخل الرقم"
            name="Camelnumber"
            value={Data.Camelnumber}
            onChange={handleInputChange}
          />
        </Form.Group>
        <Form.Group className="mb-2" controlId="formCamelFather">
          <Form.Label>اسم الاب :</Form.Label>
          <Form.Control
            type="text"
            placeholder="ادخل اسم الاب"
            name="Camelfather"
            value={Data.Camelfather}
            onChange={handleInputChange}
          />
        </Form.Group>
        <Form.Group className="mb-2" controlId="formCamelMother">
          <Form.Label>اسم الام :</Form.Label>
          <Form.Control
            type="text"
            placeholder="ادخل اسم الام"
            name="Camelmother"
            value={Data.Camelmother}
            onChange={handleInputChange}
          />
        </Form.Group>
        <Form.Group className="mb-2" controlId="formCamelType">
          <Form.Label>النوع :</Form.Label>
          <Form.Control
            type="text"
            placeholder="ادخل النوع"
            name="Cameltype"
            value={Data.Cameltype}
            onChange={handleInputChange}
          />
        </Form.Group>
        <Form.Group className="mb-2" controlId="formCamelFlag">
          <Form.Label>الموقع :</Form.Label>
          <Form.Control
            type="text"
            placeholder="ادخل الموقع"
            name="Camelflag"
            value={Data.Camelflag}
            onChange={handleInputChange}
          />
        </Form.Group>
        <Form.Group className="mb-2" controlId="formCamelslide">
          <Form.Label>الشريحة :</Form.Label>
          <Form.Control
            type="text"
            placeholder="ادخل رقم الشريحة"
            name="Camelflag"
            value={Data.Camelslide}
            onChange={handleInputChange}
          />
        </Form.Group>
        <Form.Group className="mb-2" controlId="formCamelage">
          <Form.Label>السن :</Form.Label>
          <Form.Control
            type="text"
            placeholder="ادخل السن"
            name="Camelage"
            value={Data.Camelage}
            onChange={handleInputChange}
          />
        </Form.Group>
        <Form.Group className="mb-2" controlId="formCamelpartions">
          <Form.Label>القسم :</Form.Label>
          <Form.Select
           name="Camelpartions"
           value={Data.Camelpartions}
            onChange={handleInputChange}
             >
          <option value=""> </option>
          <option value="الريس">قسم الريس</option>
          <option value="الخلفات">قسم الخلفات</option>
          <option value="اللقاح">قسم اللقاح</option>
          <option value="الحيل">قسم الحيل</option>
  </Form.Select>
        </Form.Group>
        <Form.Group className="mb-2" controlId="formCamelstatus">
          <Form.Label>الحالة :</Form.Label>
          <Form.Control
            type="text"
            placeholder="ادخل الحالة"
            name="Camelstatus"
            value={Data.Camelstatus}
            onChange={handleInputChange}
          />
        </Form.Group>
        <Form.Group className="mb-2" controlId="formCamelowner">
          <Form.Label>المالك :</Form.Label>
          <Form.Control
            type="text"
            placeholder="ادخل المالك"
            name="Camelowner"
            value={Data.Camelowner}
            onChange={handleInputChange}
          />
        </Form.Group>
        <Form.Group className="mb-2" controlId="formCamelsource">
          <Form.Label>المصدر :</Form.Label>
          <Form.Control
            type="text"
            placeholder="ادخل المصدر"
            name="Camelsource"
            value={Data.Camelsource}
            onChange={handleInputChange}
          />
        </Form.Group>
        <Form.Group className="mb-2" controlId="formCamelproduction">
          <Form.Label>الانتاج :</Form.Label>
          <Form.Control
            type="text"
            placeholder="ادخل الانتاج"
            name="Camelproduction"
            value={Data.Camelproduction}
            onChange={handleInputChange}
          />
        </Form.Group>
        <Form.Group className="mb-2" controlId="formCamelachievements">
          <Form.Label>الانجازات :</Form.Label>
          <Form.Control
            type="text"
            placeholder="ادخل الانجازات"
            name="Camelachievements"
            value={Data.Camelachievements}
            onChange={handleInputChange}
          />
        </Form.Group>
        <Form.Group className="mb-2" controlId="formCamelmarried">
          <Form.Label>اضراب :</Form.Label>
          <Form.Control
            type="text"
            placeholder="ادخل الاضراب"
            name="Camelmarried"
            value={Data.Camelmarried}
            onChange={handleInputChange}
          />
        </Form.Group>
        <Form.Group className="mb-2" controlId="formCameldatevaccine">
          <Form.Label>تاريخ اللقحة :</Form.Label>
          <Form.Control
            type="text"
            placeholder="ادخل تاريخ اللقحة"
            name="Cameldatevaccine"
            value={Data.Cameldatevaccine}
            onChange={handleInputChange}
          />
        </Form.Group>
        <Form.Group className="mb-2" controlId="formCamelnotes">
          <Form.Label>الملاحظات :</Form.Label>
          <Form.Control
           as="textarea" 
            rows={3}
            placeholder="الملاحظات"
            name="Camelnotes"
            value={Data.Camelnotes}
            onChange={handleInputChange}
          />
        </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                {dataposts.images &&
                  dataposts.images.map((image) => (
                    <Row className="d-flex" key={image.id}>
                      <Col>
                        <img
                          key={image.id}
                          src={image.image}
                          alt="camel"
                          style={{ maxWidth: "100px",width: "100px", height: "100px",  objectFit: "fill"  }}
                          onClick={() => handleImageClick(image.image)}
                        />
                      </Col>
                      <Col className=" d-flex align-items-center">
                        <Button
                          onClick={() => onDeleteImg(image.id)}
                          variant="danger"
                        >
                          <div><AiTwotoneDelete size={25} style={{ marginRight: 8,color:"black" }} /><span style={{ marginLeft: '5px', marginRight: '8px'}}>حذف</span></div>
                        </Button>
                      </Col>
                      <hr style={{ marginTop: 15, borderWidth: 2 }} />
                    </Row>
                  ))}
                {dataposts.videos &&
                  dataposts.videos.map((video) => (
                    <Row className="d-flex" key={video.id}>
                      <Col>
                        <video
                          key={video.id}
                          src={video.video}
                          controls
                          style={{ maxWidth: "150px",width: "150px", height: "120px",  objectFit: "fill" }}
                        />
                      </Col>

                      <Col className="mt-5">
                        <Button onClick={()=>onDeletevid(video.id)} variant="danger"><div><AiTwotoneDelete size={25} style={{ marginRight: 8,color:"black" }} /><span style={{ marginLeft: '5px', marginRight: '8px'}}>حذف</span></div></Button>
                      </Col>
                      <hr style={{ marginTop: 10, borderWidth: 2 }} />
                    </Row>
                  ))}
              </Form.Group>
              <Form.Group controlId="formFileMultiple" className="mb-3">
                <Form.Label>اضافة صور وفيديوهات</Form.Label>
                <Form.Control
                  onChange={(e) => {
                    setmedia(e.target.files);
                  }}
                  type="file"
                  multiple
                  accept="image/*,video/*"
                />
              </Form.Group>
              <Button
              
                style={{ float: "left" }}
                onClick={handleSubmit}
                variant="primary"
                size="lg"
              >
                {isLoading ? <Spinner animation="border" size="sm" /> : <div><RiSave2Fill size={25} style={{ marginRight: 8,color:"black" }} /><span style={{ marginLeft: '5px', marginRight: '8px'}}>حفظ التغييرات</span></div>}
              </Button>
              <Button style={{ float: "left",marginLeft:'20px'}}
                onClick={handleBackclick}
                variant="secondary"
                size="lg">
                  <div><BiArrowBack size={25} style={{transform: 'scaleX(-1)', marginRight: 8,color:"black" }} /><span style={{ marginLeft: '5px', marginRight: '8px'}}>رجوع</span></div>
              </Button>

            </Form>
          </Card>
          <Modal show={selectedImage} onHide={handleCloseModal} centered>
            <Modal.Header closeButton />
            <Modal.Body>
            <img src={selectedImage} alt="camel" style={{ objectFit: 'contain', width: '100%', height: '100%' }} />
            </Modal.Body>
            </Modal>
        </Container>
        
      ) : (
        <div style={{ 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'flex-start',
          marginTop:'20px',
        }}>
          <p style={{ 
            fontSize: '3rem', 
            textAlign: 'center',
            color:'red',
            fontFamily: 'Noto Naskh Arabic , serif'
          }}>لا يوجد بيانات</p>
        </div>
      )}
      <div>
    <ToastContainer />
    </div>
    </>
  );
}

export default Edit;
