import { useState ,useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row';
import noimg from '../background/noimg.jpg'
import { Link } from 'react-router-dom';
import { AiTwotoneEdit ,AiTwotoneDelete, } from "react-icons/ai";
import { FaPlus } from "react-icons/fa";
import { getalldata,deletedata,getpart } from '../redux/actions/postslice';
import {useSelector,useDispatch  } from "react-redux";
import { Col } from 'react-bootstrap';
import Add from './Add'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Admin() {

  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const handleModalClose = () => setShowModal(false);
  const handleModalShow = (image) => {
    setSelectedImage(image);
    setShowModal(true);
  };

  const dispatch=useDispatch();
  const dataposts= useSelector((state)=>state.posts); 

  

const onDelete = async(id) =>{
  try {
    if (window.confirm("هل تريد حذف جميع البيانات ؟")) {
    await  dispatch(deletedata(id)) 
    await  dispatch(getalldata())
    notify("تم الحذف بنجاح","Warn")
    }
  } catch (error) {
    console.log(error.message)
    
  }
}

useEffect(()=>  {
 
 
  dispatch(getalldata());

}, [dispatch])

const onPart = (part) =>{
  Part(part)
 
}

const Part= async(part)=>{
  if (part === "الكل"){
   dispatch(getalldata())
  }else {
    dispatch(getpart(part));
  }
}


const notify = (message,type) => {
  if(type==="Error")
  toast.error(message,{autoClose:2000,rtl:true,style: { width: '80%', display: 'flex', marginTop:"60px" }})
  else if (type === "Success")
  toast.success(message,{autoClose:2000,rtl:true,style: { width: '80%', display: 'flex', marginTop:"60px" }})
  else if (type === "Warn")
  toast.warn(message,{autoClose:2000,rtl:true,style: { width: '80%', display: 'flex', marginTop:"60px" }})
}

const [showAddsModal, setShowAddsModal] = useState(false);

  const handleCloseAddsModal = () => setShowAddsModal(false);
  const handleShowAddsModal = () => setShowAddsModal(true);



  return (
   <> 
   <div style={{ display: 'flex', justifyContent: 'center' }}>
   <Button onClick={handleShowAddsModal} style={{ marginTop: 10, marginRight: 8, width: '10rem' }}>  <FaPlus size={17} style={{ marginRight: 8 }} />
   <span style={{ marginLeft: '5px', marginRight: '8px' }}>اضافة</span></Button>
 </div>
   <Modal show={showAddsModal} onHide={handleCloseAddsModal}>
        <Modal.Header closeButton>
          <Modal.Title>اضافة</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Add handleCloseAddsModal={handleCloseAddsModal} notify={notify} />
        </Modal.Body>
      </Modal>

      <Row className="m-2">
      <Col className="d-flex justify-content-center">
      <div><Button style={{backgroundColor:"#07BEB8",}} onClick={()=> onPart('الكل')} className="mt-2 mx-2" size="md" variant="info">
          الكل
        </Button>
        <Button style={{backgroundColor:"#07BEB8",}} onClick={()=> onPart('اللقاح')} className="mt-2 mx-2" size="md" variant="info">
          اللقاح
        </Button>
        <Button style={{backgroundColor:"#07BEB8",}} onClick={()=> onPart('الريس')} className="mt-2 mx-2" size="md" variant="info">
          الريس
        </Button>
        <Button style={{backgroundColor:"#07BEB8",}} onClick={()=> onPart('الخلفات')} className="mt-2 mx-2" size="md" variant="info">
          الخلفات
        </Button>
        </div><style>{`
  .btn-info:hover {
    opacity: 0.8;
    color: red;
  }
`}</style>

</Col>
    </Row>
    
   {dataposts && dataposts.length > 0 ?
   <Row key={dataposts.id} data={dataposts} className="m-2 gap-3 d-flex">
        {[...dataposts].sort((a, b) => a.Camelnumber - b.Camelnumber).map((d) => {
          return <Card key={d.id} style={{ width: '16rem', marginTop: 20}}>
            <Card.Img variant="top" src={d.images && d.images.length > 0  ? d.images[0].image : noimg } style={{marginTop: 20, width: '14rem',height: '12rem' }} onClick={() => handleModalShow(d.images[0] == null ? noimg : d.images[0].image)} />
            <Card.Body>
     <Card.Title><span style={{color: 'red',fontFamily: 'Noto Naskh Arabic, serif',fontSize:20,wordBreak:'break-word',}}>{"الاسم :"}</span> <span style={{fontSize:15,wordBreak:'break-word',}}>{d.Camelname}</span></Card.Title>
   </Card.Body>
   <ListGroup className="list-group-flush">
     <ListGroup.Item ><span style={{color: 'red',fontFamily: 'Noto Naskh Arabic, serif',fontSize:20}}>{"الرقم :"}</span> <span style={{fontSize:15,wordBreak:'break-word',}}>{d.Camelnumber}</span></ListGroup.Item>
     <ListGroup.Item >
     <span style={{color: 'red',fontFamily: 'Noto Naskh Arabic, serif',fontSize:20}}>{"النوع :"}</span><span style={{fontSize:15,wordBreak:'break-word',}}>{d.Cameltype}</span> 
     </ListGroup.Item>
   </ListGroup>
   <div >
   <Card.Body >
              <Row >
                <Col >           
                 <Button  variant="danger" onClick={() => onDelete(d.id)} >
                    <AiTwotoneDelete color='black' size={22} style={{ marginLeft: '3px' }} />
                    <span style={{ marginLeft: '5px', marginRight: '5px' }}>حذف</span>
                  </Button>
                </Col>
                <Col>
                <Link to={'/Admin/Edit'} state={d.id} >
                    <Button  variant="warning">
                      <AiTwotoneEdit size={22} style={{ marginLeft: '3px' }} />
                      <span style={{ marginLeft: '5px', marginRight: '5px' }}>تعديل</span>
                    </Button>
                    </Link>

                </Col>
              </Row>
            </Card.Body>
   </div>
            <Modal show={showModal} onHide={handleModalClose} size="lg">
              <Modal.Header closeButton></Modal.Header>
              <Modal.Body>
                <img src={d.images == null ? noimg : selectedImage} alt={d.Camelname} style={{ width: '100%', height: 'auto' }} />
              </Modal.Body>
            </Modal>
          </Card>;
        })}
      </Row> : <div style={{ 
  display: 'flex', 
  justifyContent: 'center', 
  alignItems: 'flex-start',
  marginTop:'20px',
}}>
  <p style={{ 
    fontSize: '3rem', 
    textAlign: 'center',
    color:'red',
    fontFamily: 'Noto Naskh Arabic , serif'
  }}>لا يوجد بيانات</p>
</div>}
    <div>
    <ToastContainer />
    </div>
      </>
      
  )
}

export default Admin

