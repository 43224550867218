import React,{useEffect} from "react";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Cards from "./Cards";
import { getalldata,getpart } from '../redux/actions/postslice';
import {useDispatch  } from "react-redux";
import { Col } from "react-bootstrap";
function HomePage() {



  const dispatch=useDispatch();


   
  useEffect(()=>  {

    dispatch(getalldata());


}, [dispatch])


  const onPart = (part) =>{
    Part(part)

  }

  const Part= async(part)=>{
    if (part === "الكل"){
     dispatch(getalldata())
    }else {
      dispatch(getpart(part));
    }
  }
  


  return (
    <div>
     <Row className="m-2">
      <Col className="d-flex justify-content-center">
      <div><Button style={{backgroundColor:"#07BEB8",}} onClick={()=> onPart('الكل')} className="mt-2 mx-2" size="md" variant="info">
          الكل
        </Button>
        <Button style={{backgroundColor:"#07BEB8",}} onClick={()=> onPart('اللقاح')} className="mt-2 mx-2" size="md" variant="info">
          اللقاح
        </Button>
        <Button style={{backgroundColor:"#07BEB8",}} onClick={()=> onPart('الريس')} className="mt-2 mx-2" size="md" variant="info">
          الريس
        </Button>
        <Button style={{backgroundColor:"#07BEB8",}} onClick={()=> onPart('الخلفات')} className="mt-2 mx-2" size="md" variant="info">
          الخلفات
        </Button>
        <Button style={{backgroundColor:"#07BEB8",}} onClick={()=> onPart('الحيل')} className="mt-2 mx-2" size="md" variant="info">
          الحيل
        </Button>
        </div><style>{`
  .btn-info:hover {
    opacity: 0.8;
    color: red;
  }
`}</style>

</Col>
    </Row>
    <Row className="m-2 gap-3 d-flex">
      <Cards></Cards>
    </Row>
  </div>
  );
}

export default HomePage;
