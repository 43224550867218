import React,{useState,useEffect} from 'react'
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import {useDispatch  } from "react-redux";
import { getalldata,getDataSearch } from '../redux/actions/postslice';
import background from "../background/camelicon.png";

function NavBar() {
  const onSearch = (name) =>{
    search(name)

  }
  const dispatch=useDispatch();

  const search= async(name)=>{
    if (name === ""){
     dispatch(getalldata())
    }else {
      dispatch(getDataSearch(name))
    }
  }
  const [currentPage, setCurrentPage] = useState('الصفحة الرئيسية');
  const pathname = window.location.pathname;
  
  useEffect(() => {
    
    if (pathname === '/') {
      setCurrentPage('الصفحة الرئيسية');
    } else if (pathname === '/Login') {
      setCurrentPage('تسجيل دخول');
    }
    else if (pathname === '/Admin') {
      setCurrentPage('الادارة');
    }
  }, [pathname]);


  return (
<div>
<Navbar bg="light" expand="lg">
    <Container fluid>
      <Navbar.Brand href="/" > <img
        src={background}
        className="d-inline-block align-top"
        alt="Logo"
        style={{marginLeft:10, width: 60, height: 50}}
      />  <span style={{display: 'inline-block', marginTop:"10px"}}>{currentPage}</span>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="navbarScroll" />
      <Navbar.Collapse id="navbarScroll">
        <Nav
          className="me-auto my-2 my-lg-0"
          style={{ maxHeight: '100px' }}
          navbarScroll
        >
          <Nav.Link href="/">الصفحة الرئيسية</Nav.Link>
          <Nav.Link href="Login" >تسجيل دخول</Nav.Link>
        </Nav>
        <Form className="d-flex">
          <Form.Control
            type="search"
            placeholder="بحث"
            className="me-2"
            aria-label="Search"
            onChange={(e)=>onSearch(e.target.value)}
          />
        </Form>
      </Navbar.Collapse>
    </Container>
  </Navbar>
</div>
  )
}

export default NavBar