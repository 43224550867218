import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {posts:null}

const apiURL = "https://nodejs.al-halals.com/";

export const getalldata = createAsyncThunk("posts/getall", async () => {
  const response = await axios.get(apiURL + "getall");

return response.data;
  
});

export const getbyid = createAsyncThunk("posts/getbyid", async (id) => {
    const response = await axios.get(apiURL + `get_id/${id}`);
    return response.data;
    
  });
  
  export const getpart = createAsyncThunk("posts/getpart", async (part) => {
    const response = await axios.get(apiURL + `getpart/${part}`);
    return response.data;
    
  });

  
export const getDataSearch = createAsyncThunk("posts/search", async (name) => {
  const response = await axios.get(apiURL + `search/${name}`);
  return response.data;
});

export const addmedia = createAsyncThunk(
  "posts/addmedia",
  async (inputData) => {
    const response = await axios.post(apiURL + "addmedia", inputData);
    return response.data;
  }
);

export const adddata = createAsyncThunk("posts/adddata", async (inputData) => {
  const response = await axios.post(apiURL + "addnewpost", inputData);
  
  return response.data;
});

export const editdata = createAsyncThunk(
  "posts/edit",
  async ({id, inputData}) => {
    const response = await axios.put(apiURL + `updatepost/${id}`, inputData);
    console.log(response.data);
    return response.data;
  }
);

export const deletedata = createAsyncThunk("posts/delete", async (id) => {
  const response = await axios.delete(apiURL + `deletepost/${id}`);
  return response.data;
});

export const deleteimg = createAsyncThunk("posts/deleteimg", async (id) => {
  const response = await axios.delete(apiURL + `deleteimage/${id}`);
  return response.data;
});

export const deletevid= createAsyncThunk("posts/deletevid", async (id) => {
  const response = await axios.delete(apiURL + `deletevideo/${id}`);
  return response.data;
});

const postSlice = createSlice({
  name: "posts",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getalldata.fulfilled, (state, action) => {
        return action.payload;
      })
      .addCase(getbyid.fulfilled, (state, action) => {
        return action.payload
      })
      .addCase(getpart.fulfilled, (state, action) => {
        return action.payload
      })
      .addCase(getDataSearch.fulfilled, (state, action) => {
        return action.payload;
      })
      .addCase(adddata.fulfilled, (state, action) => {
        state.push(action.payload);
      })
      .addCase(editdata.fulfilled, (state, action) => {
        return action.payload;
      })
      .addCase(deletedata.fulfilled, (state, action) => {
        return state.filter((post) => post.id !== action.payload.id);
      })
      .addCase(addmedia.fulfilled, (state, action) => {
        const index = state.findIndex((post) => post.id === action.payload.camleimgid);
        state[index].images.push(action.payload);
      })
      .addCase(deleteimg.fulfilled, (state, action) => {
        if (state.posts && state.posts.images) {
          const filteredImages = state.posts.images.filter(image => image.id !== action.data.id);
          console.log(filteredImages);
          return {
              ...state,
              post: {
                  ...state.posts,
                  images: filteredImages
              }
          };
      } else {
          return state;
      }
      })
      .addCase(deletevid.fulfilled, (state, action) => {
        if (state.posts && state.posts.videos) {
          const filteredVideos = state.posts.videos.filter(video => video.id !== action.data.id);
          console.log(filteredVideos);
          return {
              ...state,
              post: {
                  ...state.posts,
                  videos: filteredVideos
              }
          };
      } else {
          return state;
      }
      });
  },
 
});

export default postSlice.reducer;
