import NavBar from './components/NavBar';
import { BrowserRouter ,Routes,Route } from "react-router-dom";
import background from "./background/4.jpg";
import HomePage from './components/HomePage';
import Login from './components/Login';
import Carddetails from './components/Carddetails';
import Admin from './components/Admin'
import Edit from './components/Edit';


function App() {
  return (
    <div  
    style={{ backgroundImage: `url(${background})`,backgroundPosition: 'center',backgroundPositionY:"40px",
        backgroundSize: '100% 100%',
        position:'absolute' ,
        minHeight:"100%",
        minWidth:"100%",
        }}
        >
    <NavBar></NavBar>
     <BrowserRouter>
     <Routes>
        <Route path="/" element={<HomePage/> }/>
        <Route path="/Login" element={<Login/>}/>
        <Route path="/Carddetails" element={<Carddetails/>}/>
          <Route element={<Login/>}>
              <Route path="/Admin" element={<Admin/>}/>
              <Route path="/Admin/Edit" element={<Edit/>}/>
              <Route path="/Admin/Login" element={<Login/>}/>
          </Route> 
      </Routes>
     </BrowserRouter>
    </div>
  );
}

export default App;
